export default async function copyTextToClipboard() {
    let text = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '';
    try {
        await navigator.clipboard.writeText(text);
    } catch  {
        const temporaryInput = document.createElement('textarea');
        temporaryInput.style.position = 'fixed';
        temporaryInput.style.top = '0';
        temporaryInput.style.left = '0';
        document.body.append(temporaryInput);
        temporaryInput.setRangeText(text);
        temporaryInput.select();
        temporaryInput.setSelectionRange(0, 99999);
        document.execCommand('copy');
        temporaryInput.remove();
    }
}
