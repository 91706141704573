import { logger } from '@leon-hub/logging';
import copyTextToClipboard from 'web/src/utils/browser/copyTextToClipboard';
import { useTooltipStore } from 'web/src/modules/dialogs/store';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useCopyToClipboard() {
    async function copy(tooltip) {
        const { $translate } = useI18n();
        try {
            await copyTextToClipboard(tooltip.data);
            useTooltipStore().showTooltip({
                ...tooltip,
                notificationText: tooltip.notificationText || $translate('WEB2_COPY_TEXT').value
            });
        } catch (error) {
            logger.warn('Failed to copy text to clipboard', error);
        }
    }
    return {
        copy
    };
}
