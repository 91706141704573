import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
import { computed, toRef } from 'vue';
import { Timer } from '@leon-hub/utils';
import { CaptchaType } from '@leon-hub/api-sdk/src/sdk/sdk';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { VLinearLoader } from '@components/loader';
import { DialogAction, DialogComponent } from 'web/src/modules/dialogs/enums';
import { useDialogsStore } from 'web/src/modules/dialogs/store';
import VCaptcha from 'web/src/modules/captcha/components/VCaptcha/VCaptcha.vue';
import VReCaptchaBadge from 'web/src/modules/captcha/components/VReCaptchaBadge/VReCaptchaBadge.vue';
import { ReCaptchaFramedWrapperState } from 'web/src/modules/captcha/components/VReCaptcha/enums';
import { useCaptchaStore } from 'web/src/modules/captcha/store';
import DefaultReCaptchaService from 'web/src/modules/captcha/services/DefaultReCaptchaService';
import { useBaseModalActions } from 'web/src/modules/dialogs/composables';
import ConfirmModal from '../ConfirmModal/ConfirmModal.vue';
const empiricalEmitCloseDelayMs = 500;
export default /*@__PURE__*/ _defineComponent({
    __name: 'CaptchaModal',
    props: {
        modal: {},
        isAlert: {
            type: Boolean
        }
    },
    emits: [
        "challenge-is-closed",
        "challenge-is-opened",
        "close",
        "button-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const bus = useEventsBus();
        const captchaStore = useCaptchaStore();
        const { captchaSettingsForStrategy } = captchaStore;
        const { onClose, onButtonClick } = useBaseModalActions(emit);
        const { setFullHeight } = useDialogsStore();
        const captchaForStrategy = computed(()=>captchaSettingsForStrategy(props.modal.captchaRequesterStrategy, props.modal.captchaType).value);
        const captchaSettings = computed(()=>({
                ...captchaForStrategy.value,
                captchaRequesterStrategy: props.modal.captchaRequesterStrategy
            }));
        const isAutoExecute = computed(()=>captchaSettings.value.captchaType === CaptchaType.INVISIBLE_RECAPTCHA || captchaSettings.value.captchaType === CaptchaType.ANDROID_RECAPTCHA);
        const useIframeForCaptcha = toRef(captchaStore, 'useIframeForCaptcha');
        const captchaIframeUrl = toRef(captchaStore, 'captchaIframeUrl');
        const apiUrl = toRef(captchaStore, 'apiUrl');
        const captchaProperties = computed(()=>({
                captchaRequesterStrategy: captchaSettings.value.captchaRequesterStrategy,
                captchaEnabled: true,
                siteKey: captchaSettings.value.siteKey,
                captchaType: captchaSettings.value.captchaType,
                reCaptchaTheme: captchaSettings.value.theme,
                autoExecute: isAutoExecute.value,
                useIframeForCaptcha: useIframeForCaptcha.value,
                captchaIframeUrl: captchaIframeUrl.value,
                apiUrl: apiUrl.value
            }));
        const confirmModalProps = computed(()=>{
            const { id, captchaRequesterStrategy, captchaType, ...modalProps } = props.modal;
            return {
                modal: {
                    ...modalProps,
                    modalComponent: DialogComponent.ConfirmModal
                },
                isAlert: props.isAlert
            };
        });
        function closeModal(value) {
            /**
   * ReCaptcha challenge workaround for problematic/slow/buggy devices that didn't handle the challenge on time.
   */ const emitClose = (isRetry)=>{
                Timer.setTimeout(()=>{
                    if (!isRetry && DefaultReCaptchaService.getInstance().isChallengeVisible()) emitClose(true);
                    else onButtonClick({
                        action: DialogAction.MODAL_CLOSE,
                        value: value ?? void 0
                    });
                }, empiricalEmitCloseDelayMs);
            };
            emitClose(false);
        }
        function onChallengeClose() {
            bus.emit(BusEvent.CAPTCHA_CHALLENGE_IS_CLOSED, {});
            closeModal(null);
            emit('challenge-is-closed');
        }
        function onVerify(param) {
            let { captchaResponse } = param;
            closeModal({
                captchaResponse
            });
        }
        function onError(error) {
            closeModal({
                captchaResponse: error
            });
        }
        function onWrapperState(state) {
            if (state === ReCaptchaFramedWrapperState.Expand) setFullHeight(props.modal.id, true);
            else if (state === ReCaptchaFramedWrapperState.Collapse) setFullHeight(props.modal.id, false);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ConfirmModal, _mergeProps(confirmModalProps.value, {
                onClose: _unref(onClose),
                onButtonClick: _unref(onButtonClick)
            }), {
                default: _withCtx(()=>[
                        isAutoExecute.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['captcha-padding'])
                            }, [
                                _createVNode(_unref(VLinearLoader), {
                                    hint: _ctx.$t('WEB2_CAPTCHA_CHECK_MODAL_CAPTION')
                                }, null, 8, [
                                    "hint"
                                ])
                            ], 2)
                        ])) : _createCommentVNode("", true),
                        _createVNode(VCaptcha, _mergeProps({
                            class: _ctx.$style['captcha-margin-bottom']
                        }, captchaProperties.value, {
                            onVerify: onVerify,
                            onWrapperState: onWrapperState,
                            onError: onError,
                            onChallengeIsClosed: onChallengeClose,
                            onChallengeIsOpened: _cache[0] || (_cache[0] = ($event)=>emit('challenge-is-opened'))
                        }), null, 16, [
                            "class"
                        ]),
                        _createVNode(VReCaptchaBadge, {
                            lang: _ctx.$lang
                        }, null, 8, [
                            "lang"
                        ])
                    ]),
                _: 1
            }, 16, [
                "onClose",
                "onButtonClick"
            ])), [
                [
                    _directive_auto_id,
                    'CaptchaModal'
                ]
            ]);
        };
    }
});
