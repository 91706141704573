import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "countdown"
};
const _hoisted_2 = {
    class: "countdown__timer"
};
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { Timer } from '@leon-hub/utils';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { useBaseModalActions } from 'web/src/modules/dialogs/composables';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import ConfirmModal from '../ConfirmModal/ConfirmModal.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CountDownModal',
    props: {
        modal: {},
        isAlert: {
            type: Boolean
        }
    },
    emits: [
        "close",
        "button-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { onClose, onButtonClick } = useBaseModalActions(emit);
        let interval = 0;
        const countDown = ref(60);
        function startCountDown() {
            if (countDown.value) countDown.value -= 1;
            if (0 === countDown.value) onButtonClick({
                action: DialogAction.MODAL_COUNTDOWN
            });
        }
        onMounted(()=>{
            interval = Timer.setInterval(startCountDown, 1000);
        });
        onBeforeUnmount(()=>{
            if (interval) {
                Timer.clearInterval(interval);
                interval = 0;
            }
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ConfirmModal, {
                modal: _ctx.modal,
                "is-alert": _ctx.isAlert,
                onClose: _unref(onClose),
                onButtonClick: _unref(onButtonClick)
            }, _createSlots({
                default: _withCtx(()=>[
                        _createElementVNode("div", _hoisted_1, [
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_SELF_EXCLUSION_AFTER')) + ": ", 1),
                            _createElementVNode("span", _hoisted_2, _toDisplayString(countDown.value) + " " + _toDisplayString(_ctx.$t('JS_TIMER_TEXT_SECONDS')), 1)
                        ])
                    ]),
                _: 2
            }, [
                void 0
            ]), 1032, [
                "modal",
                "is-alert",
                "onClose",
                "onButtonClick"
            ])), [
                [
                    _directive_auto_id,
                    'CountDownModal'
                ]
            ]);
        };
    }
});
