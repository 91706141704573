import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import { useBaseModalActions } from 'web/src/modules/dialogs/composables';
import { DialogComponent } from 'web/src/modules/dialogs/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BonusWithdrawalModal',
    props: {
        modal: {},
        isAlert: {
            type: Boolean
        }
    },
    emits: [
        "close",
        "button-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { onClose, onButtonClick } = useBaseModalActions(emit);
        const confirmModalProps = computed(()=>{
            const { money, ...modalProps } = props.modal;
            return {
                ...modalProps,
                modalComponent: DialogComponent.ConfirmModal
            };
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ConfirmModal, {
                modal: confirmModalProps.value,
                "is-alert": _ctx.isAlert,
                class: _normalizeClass(_ctx.$style['bonus-withdrawals']),
                onClose: _unref(onClose),
                onButtonClick: _unref(onButtonClick)
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['bonus-withdrawals__money'])
                        }, _toDisplayString(_ctx.modal.money), 3)
                    ]),
                _: 1
            }, 8, [
                "modal",
                "is-alert",
                "class",
                "onClose",
                "onButtonClick"
            ])), [
                [
                    _directive_auto_id,
                    'BonusWithdrawalModal'
                ]
            ]);
        };
    }
});
