import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import { useBaseModalActions } from 'web/src/modules/dialogs/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ConfirmDepositModal',
    props: {
        modal: {},
        isAlert: {
            type: Boolean
        }
    },
    emits: [
        "close",
        "button-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const { onClose, onButtonClick } = useBaseModalActions(emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ConfirmModal, {
                modal: {
                    ..._ctx.modal,
                    confirmMessage: void 0
                },
                "is-alert": _ctx.isAlert,
                onClose: _unref(onClose),
                onButtonClick: _unref(onButtonClick)
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("span", {
                            class: _normalizeClass([
                                _ctx.$style['deposit-modal--label']
                            ])
                        }, _toDisplayString(_ctx.modal.confirmMessage), 3)
                    ]),
                _: 1
            }, 8, [
                "modal",
                "is-alert",
                "onClose",
                "onButtonClick"
            ])), [
                [
                    _directive_auto_id,
                    'ConfirmDepositModal'
                ]
            ]);
        };
    }
});
