export default function useBaseModalActions(emit) {
    function onClose() {
        emit('close');
    }
    function onButtonClick(event) {
        emit('button-click', event);
    }
    return {
        onClose,
        onButtonClick
    };
}
