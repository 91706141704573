import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed, onMounted } from 'vue';
import { TargetParamHistory } from '@leon-hub/yandex-metrika';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import PaymentsWithdrawalsType, { PaymentsNDFLType } from 'web/src/modules/payments/types/PaymentsWithdrawalsTypes';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useBaseModalActions } from 'web/src/modules/dialogs/composables';
import { DialogAction, DialogComponent } from 'web/src/modules/dialogs/enums';
import ConfirmModal from '../ConfirmModal/ConfirmModal.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ConfirmWithdrawalModal',
    props: {
        modal: {},
        isAlert: {
            type: Boolean
        }
    },
    emits: [
        "close",
        "button-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { onClose, onButtonClick } = useBaseModalActions(emit);
        const localStorageManager = useLocalStorageManager();
        const analytics = useAnalytics();
        const showPaymentAmount = computed(()=>props.modal.type === PaymentsWithdrawalsType.INCOME_TAX);
        const showBalance = computed(()=>props.modal.type === PaymentsWithdrawalsType.DEFAULT);
        function onHistoryClick() {
            analytics.clickMap({
                history: TargetParamHistory.REQUEST_WITHDRAWAL
            });
            emit('button-click', {
                action: DialogAction.ADDITIONAL_BUTTON
            });
            onClose();
        }
        const confirmModalProps = computed(()=>{
            const { priority, ...modalProps } = props.modal;
            return {
                modal: {
                    ...modalProps,
                    priority,
                    modalComponent: DialogComponent.ConfirmModal,
                    confirmMessage: void 0
                },
                isAlert: props.isAlert
            };
        });
        onMounted(()=>{
            if (showPaymentAmount.value) {
                localStorageManager.removeItem(PaymentsNDFLType.NETTO_AMOUNT);
                localStorageManager.removeItem(PaymentsNDFLType.BRUTTO_AMOUNT);
                localStorageManager.removeItem(PaymentsNDFLType.TAX_AMOUNT);
            }
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ConfirmModal, _mergeProps(confirmModalProps.value, {
                class: _ctx.$style['withdrawal-modal--confirm'],
                onClose: _unref(onClose),
                onButtonClick: _unref(onButtonClick)
            }), {
                default: _withCtx(()=>[
                        showPaymentAmount.value ? (_openBlock(), _createBlock(_unref(TextSlotPlaceholder), {
                            key: 0,
                            text: _ctx.$t('WEB2_WTHDRAWAL_MODAL_PAYMENT_AMOUNT'),
                            class: _normalizeClass([
                                _ctx.$style['withdrawal-modal__label'],
                                _ctx.$style['withdrawal-modal__label-payment-amount']
                            ])
                        }, {
                            amount: _withCtx(()=>[
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['withdrawal-modal__label-payment-amount-bold'])
                                    }, _toDisplayString(_ctx.modal.amount), 3)
                                ]),
                            _: 1
                        }, 8, [
                            "text",
                            "class"
                        ])) : _createCommentVNode("", true),
                        _createElementVNode("span", {
                            class: _normalizeClass([
                                _ctx.$style['withdrawal-modal__label'],
                                _ctx.$style['withdrawal-modal__label-cancel']
                            ])
                        }, _toDisplayString(_ctx.$t('WEB2_WTHDRAWAL_MODAL_OTMENIT')), 3),
                        _createElementVNode("span", {
                            class: _normalizeClass([
                                _ctx.$style['withdrawal-modal__label'],
                                _ctx.$style['withdrawal-modal__label-history']
                            ]),
                            onClick: onHistoryClick
                        }, _toDisplayString(_ctx.$t('WEB2_WITHDRAWAL_MODAL_ISTORII')), 3),
                        showBalance.value ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass([
                                    _ctx.$style['withdrawal-modal__label'],
                                    _ctx.$style['withdrawal-modal__label-available-balance']
                                ])
                            }, _toDisplayString(_ctx.$t('WEB2_WITHDRAWAL_MODAL_DOSTUPNI')), 3),
                            _createElementVNode("span", {
                                class: _normalizeClass([
                                    _ctx.$style['withdrawal-modal__label'],
                                    _ctx.$style['withdrawal-modal__label-balance']
                                ])
                            }, _toDisplayString(_ctx.modal.amount), 3)
                        ], 64)) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 16, [
                "class",
                "onClose",
                "onButtonClick"
            ])), [
                [
                    _directive_auto_id,
                    'ConfirmWithdrawalModal'
                ]
            ]);
        };
    }
});
