import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import { useBaseModalActions } from 'web/src/modules/dialogs/composables';
import { DialogComponent } from 'web/src/modules/dialogs/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TfaCodesModal',
    props: {
        modal: {},
        isAlert: {
            type: Boolean
        }
    },
    emits: [
        "close",
        "button-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { onClose, onButtonClick } = useBaseModalActions(emit);
        const confirmModalProps = computed(()=>{
            const { codes, ...modalProps } = props.modal;
            return {
                modal: {
                    ...modalProps,
                    modalComponent: DialogComponent.ConfirmModal
                },
                isAlert: props.isAlert
            };
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ConfirmModal, _mergeProps(confirmModalProps.value, {
                "is-scroll-bar-enabled": "",
                onClose: _unref(onClose),
                onButtonClick: _unref(onButtonClick)
            }), {
                default: _withCtx(()=>[
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modal.codes, (code)=>(_openBlock(), _createElementBlock("div", {
                                key: code,
                                class: _normalizeClass(_ctx.$style['tfa-code'])
                            }, _toDisplayString(code), 3))), 128))
                    ]),
                _: 1
            }, 16, [
                "onClose",
                "onButtonClick"
            ])), [
                [
                    _directive_auto_id,
                    'TfaCodesModal'
                ]
            ]);
        };
    }
});
