import { computed, toRef } from 'vue';
import { useDialogsStore, useTooltipStore } from 'web/src/modules/dialogs/store';
import { useDesktopModalStore } from 'web/src/modules/core/store';
import { ModalSelector } from 'web/src/modules/core/enums';
import { DialogAction } from 'web/src/modules/dialogs/enums';
export default function useTheModal() {
    const dialogsStore = useDialogsStore();
    const currentDialog = toRef(dialogsStore, 'currentDialog');
    const desktopModalStore = useDesktopModalStore();
    const isProfileLayout = toRef(desktopModalStore, 'isProfileLayout');
    const hasDesktopModal = toRef(desktopModalStore, 'hasDesktopModal');
    const tooltipStore = useTooltipStore();
    const tooltip = toRef(tooltipStore, 'tooltip');
    const currentModal = computed(()=>currentDialog.value?.dialog);
    const currentModalSelector = computed(()=>hasDesktopModal.value ? ModalSelector.DESKTOP_INNER_MODAL : ModalSelector.BODY);
    const hasTooltip = computed(()=>!!tooltip.value);
    const tooltipProps = computed(()=>({
            text: tooltip.value?.notificationText,
            isProfileModalTooltip: tooltip.value?.isProfileModalTooltip
        }));
    function onButtonClick(param) {
        let { action, value } = param;
        if (currentModal.value) dialogsStore.callAction(currentModal.value.id, action, value);
    }
    function onCurrentModalClose() {
        if (currentModal.value) dialogsStore.callAction(currentModal.value.id, DialogAction.MODAL_CLOSE);
    }
    return {
        currentModalSelector,
        currentModal,
        hasDesktopModal,
        isProfileLayout,
        onCurrentModalClose,
        onButtonClick,
        hasTooltip,
        tooltipProps
    };
}
