/* eslint-disable no-param-reassign */ import { useDialogsStore } from '../store';
const ModalsPlugin = {
    install (app) {
        const dialogsStore = useDialogsStore();
        app.config.globalProperties.$showModal = dialogsStore.showDialog;
        app.config.globalProperties.$closeModal = dialogsStore.closeDialog;
        app.config.globalProperties.$destroyModals = dialogsStore.closeAllDialogs;
    }
};
export default ModalsPlugin;
