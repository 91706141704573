import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Timer } from '@leon-hub/utils';
const useTooltipStore = defineStore('tooltip', ()=>{
    const tooltip = ref();
    let timer = 0;
    function showTooltip(value) {
        tooltip.value = value;
        timer = Timer.setTimeout(closeTooltip, value.duration || 1500);
    }
    function closeTooltip() {
        if (timer > 0) {
            Timer.clearTimeout(timer);
            timer = 0;
        }
        tooltip.value = void 0;
    }
    return {
        tooltip,
        showTooltip
    };
});
export default useTooltipStore;
