import { defineComponent as _defineComponent } from 'vue';
import { resolveDynamicComponent as _resolveDynamicComponent, unref as _unref, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { DialogComponent } from 'web/src/modules/dialogs/enums';
import ConfirmDepositModal from 'web/src/modules/dialogs/views/ConfirmDepositModal/ConfirmDepositModal.vue';
import BonusWithdrawalModal from 'web/src/modules/dialogs/views/BonusWithdrawalModal/BonusWithdrawalModal.vue';
import CmsContentModal from 'web/src/modules/dialogs/views/CmsContentModal/CmsContenModal.vue';
import CaptchaModal from 'web/src/modules/dialogs/views/CaptchaModal/CaptchaModal.vue';
import { useBaseModalActions } from 'web/src/modules/dialogs/composables';
import ConfirmWithdrawalModal from 'web/src/modules/dialogs/views/ConfirmWithdrawalModal/ConfirmWithdrawalModal.vue';
import CountDownModal from 'web/src/modules/dialogs/views/CountDownModal/CountDownModal.vue';
import TfaCodesModal from 'web/src/modules/dialogs/views/TfaCodesModal/TfaCodesModal.vue';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'UniversalModalView',
    props: {
        modal: {},
        isInnerModal: {
            type: Boolean
        },
        isProfileLayout: {
            type: Boolean
        }
    },
    emits: [
        "close",
        "button-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { onClose, onButtonClick } = useBaseModalActions(emit);
        const component = computed(()=>{
            switch(props.modal.modalComponent){
                case DialogComponent.BonusWithdrawalModal:
                    return BonusWithdrawalModal;
                case DialogComponent.CaptchaModal:
                    return CaptchaModal;
                case DialogComponent.CmsContentModal:
                    return CmsContentModal;
                case DialogComponent.ConfirmDepositModal:
                    return ConfirmDepositModal;
                case DialogComponent.ConfirmWithdrawalModal:
                    return ConfirmWithdrawalModal;
                case DialogComponent.CountDownModal:
                    return CountDownModal;
                case DialogComponent.TfaCodesModal:
                    return TfaCodesModal;
                default:
                    return ConfirmModal;
            }
        });
        const modalProps = computed(()=>({
                ...props.modal,
                isInnerModal: props.isInnerModal,
                width: props.isInnerModal ? void 0 : props.modal.width,
                isProfileLayout: props.isProfileLayout || props.modal.isProfileLayout
            }));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(component.value), {
                modal: modalProps.value,
                "is-alert": !_ctx.isInnerModal,
                onClose: _unref(onClose),
                onButtonClick: _unref(onButtonClick)
            }, null, 40, [
                "modal",
                "is-alert",
                "onClose",
                "onButtonClick"
            ])), [
                [
                    _directive_auto_id,
                    'UniversalModalView'
                ]
            ]);
        };
    }
});
