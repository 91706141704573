export var DialogPriority;
var DialogPriority1;
(DialogPriority1 = DialogPriority || (DialogPriority = {}))[DialogPriority1["default"] = 1] = "default", DialogPriority1[DialogPriority1["notification"] = 2] = "notification", DialogPriority1[DialogPriority1["error"] = 3] = "error";
export var AnalyticsModalType;
var AnalyticsModalType1;
(AnalyticsModalType1 = AnalyticsModalType || (AnalyticsModalType = {}))["WARNING"] = "warning", AnalyticsModalType1["WARNING_EGS"] = "warningEGS";
export var DialogComponent;
var DialogComponent1;
(DialogComponent1 = DialogComponent || (DialogComponent = {}))["ConfirmModal"] = "ConfirmModal", DialogComponent1["CountDownModal"] = "CountDownModal", DialogComponent1["ConfirmWithdrawalModal"] = "ConfirmWithdrawalModal", DialogComponent1["ConfirmDepositModal"] = "ConfirmDepositModal", DialogComponent1["BonusWithdrawalModal"] = "BonusWithdrawalModal", DialogComponent1["CaptchaModal"] = "CaptchaModal", DialogComponent1["TfaCodesModal"] = "TfaCodesModal", DialogComponent1["CmsContentModal"] = "CmsContentModal";
export var PresetName;
var PresetName1;
(PresetName1 = PresetName || (PresetName = {}))["ALERT_SUCCESS"] = "ALERT_SUCCESS", PresetName1["ALERT_SUCCESS_NO_BUTTONS"] = "ALERT_SUCCESS_NO_BUTTONS", PresetName1["ALERT_WARNING"] = "ALERT_WARNING", PresetName1["ALERT_ERROR"] = "ALERT_ERROR", PresetName1["CONFIRM"] = "CONFIRM", PresetName1["CAPTCHA"] = "CAPTCHA", PresetName1["CMS_MODAL"] = "CMS_MODAL";
export var DialogAction;
var DialogAction1;
(DialogAction1 = DialogAction || (DialogAction = {}))["MODAL_CLOSE"] = "modalClose", DialogAction1["MODAL_DESTROY"] = "modalDestroy", DialogAction1["CONFIRM"] = "confirm", DialogAction1["SECONDARY_CLICK"] = "secondaryClick", DialogAction1["ADDITIONAL_BUTTON"] = "additionalButton", DialogAction1["MODAL_COUNTDOWN"] = "modalCountDown";
export var ModalProvidable;
(ModalProvidable || (ModalProvidable = {}))["modalContext"] = "modalContext";
