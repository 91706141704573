import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import { useBaseModalActions } from 'web/src/modules/dialogs/composables';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import { DialogComponent } from 'web/src/modules/dialogs/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CmsContenModal',
    props: {
        modal: {},
        isAlert: {
            type: Boolean
        }
    },
    emits: [
        "close",
        "button-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { onClose, onButtonClick } = useBaseModalActions(emit);
        const confirmModalProps = computed(()=>{
            const { cmsKey, noPadding, ...modalProps } = props.modal;
            return {
                ...modalProps,
                modalComponent: DialogComponent.ConfirmModal
            };
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ConfirmModal, {
                modal: confirmModalProps.value,
                "is-alert": _ctx.isAlert,
                "is-scroll-bar-enabled": "",
                class: _normalizeClass([
                    "cms-content-modal",
                    _ctx.modal.class
                ]),
                onClose: _unref(onClose),
                onButtonClick: _unref(onButtonClick)
            }, {
                default: _withCtx(()=>[
                        _createVNode(VCmsContent, {
                            "cms-key": _ctx.modal.cmsKey,
                            "no-padding": _ctx.modal.noPadding,
                            silent: ""
                        }, null, 8, [
                            "cms-key",
                            "no-padding"
                        ])
                    ]),
                _: 1
            }, 8, [
                "modal",
                "is-alert",
                "class",
                "onClose",
                "onButtonClick"
            ])), [
                [
                    _directive_auto_id,
                    'CmsContenModal'
                ]
            ]);
        };
    }
});
