import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, Teleport as _Teleport, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { provide } from 'vue';
import { ModalProvidable } from 'web/src/modules/dialogs/enums';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import VTooltip from 'web/src/modules/dialogs/components/VTooltip/VTooltip.vue';
import UniversalModalView from '../UniversalModalView/UniversalModalView.vue';
import { useTheModal } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TheModal',
    setup (__props) {
        provide(ModalProvidable.modalContext, {
            insideModal: true
        });
        const { currentModalSelector, currentModal, hasDesktopModal, isProfileLayout, onCurrentModalClose, onButtonClick, hasTooltip, tooltipProps } = useTheModal();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createVNode(ModalPortal, {
                    selector: _unref(currentModalSelector)
                }, {
                    default: _withCtx(()=>[
                            _unref(currentModal) ? (_openBlock(), _createBlock(UniversalModalView, {
                                key: 0,
                                modal: _unref(currentModal),
                                "is-inner-modal": _unref(hasDesktopModal),
                                "is-profile-layout": _unref(isProfileLayout),
                                onClose: _unref(onCurrentModalClose),
                                onButtonClick: _unref(onButtonClick)
                            }, null, 8, [
                                "modal",
                                "is-inner-modal",
                                "is-profile-layout",
                                "onClose",
                                "onButtonClick"
                            ])) : _createCommentVNode("", true)
                        ]),
                    _: 1
                }, 8, [
                    "selector"
                ]),
                _unref(hasTooltip) ? (_openBlock(), _createBlock(_Teleport, {
                    key: 0,
                    to: _unref(currentModalSelector),
                    disabled: !_unref(hasTooltip)
                }, [
                    _createVNode(VTooltip, _normalizeProps(_guardReactiveProps(_unref(tooltipProps))), null, 16)
                ], 8, [
                    "to",
                    "disabled"
                ])) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'TheModal'
                ]
            ]);
        };
    }
});
